import React, { useState } from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { ServicesProps } from 'components/flex/Services/ServicesShell'
import NavLink from 'components/shared/NavLink'

const Section = styled.div`
  z-index: 7;
`

const ServicesWrapper = styled.div`
  z-index: 2;

  @media (min-width: 1200px) {
    margin-top: -8rem;
  }
`

const Service = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  aspect-ratio: 1/1;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(0.95);
  }
`

const Icon = styled(Plaatjie)`
  & img {
    object-fit: contain !important;
  }

  @media (max-width: 1199.98px) {
    width: 50%;
  }

  @media (max-width: 575.98px) {
    width: 75%;
  }
`

const Content = styled(ParseContent)`
  font-family: ${({ theme }) => theme.font.family.secondary};
  line-height: ${({ theme }) => theme.font.size.large};

  @media (min-width: 1200px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.small};
    line-height: ${({ theme }) => theme.font.size.medium};
  }
`

const Text = styled(ParseContent)`
  @media (max-width: 991px) {
    & * {
      text-align: left !important;
    }
  }
`

const Services: React.FC<ServicesProps> = ({ fields }) => (
  <Section className="position-relative mb-5 pb-lg-5" id={fields.sectionid}>
    <div className="container py-5">
      <div className="row">
        <ServicesWrapper className="position-relative col-12 col-lg-6">
          <div className="row">
            {fields.services?.map((service, index) => (
              <div className="col-6 col-md-3 col-lg-6 mb-4" key={index}>
                <NavLink to={service?.link?.url || '#'}>
                  <Service className="d-flex flex-column justify-content-between align-items-center pt-lg-5 pb-lg-4 py-3 py-lg-0 px-4">
                    <Icon image={service?.icon} alt="" />
                    <Content
                      className="text-center"
                      content={service?.link?.title}
                    />
                  </Service>
                </NavLink>
              </div>
            ))}
          </div>
        </ServicesWrapper>
        <div className="col-12 col-lg-6">
          <Text content={fields.description} className="ps-lg-5 mt-4 mt-lg-0" />
        </div>
      </div>
    </div>
  </Section>
)

export default Services
